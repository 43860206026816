<template>
    <div>
        <!-- Banner -->
        <base-inner-banner
            :banner-image="pageBanner"
            :is-custom-banner="true"
            :banner="banner"
        ></base-inner-banner>
        <!-- Banner -->

        <div class="content-wrapper">
            <div class="container">
                <!-- Page Head -->
                <base-page-head :page-head="pagehead"></base-page-head>
                <!-- Page Head -->

                <!-- Page Contents -->
                <div class="page-contents userAccount">
                    <div class="sidenav-wrapper">
                        <aside class="left-col">
                            <account-nav></account-nav>
                        </aside>
                        <div class="right-col">
                            <div class="mentorship">
                                <h2 class="page__title">
                                    {{ $t('Mentoring – Ideas listing') }}
                                </h2>
                                <!-- idealistingData -->
                                <mentorships-list
                                    :mentorships="mentorships"
                                ></mentorships-list>
                                <!-- idealistingData -->
                                <base-loader v-if="loadding" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import imgMedium from '@/assets/images/banners/mantorship-banner.jpg'
import imgLarge from '@/assets/images/banners/mantorship-banner.jpg'
import imgBig from '@/assets/images/banners/mantorship-banner.jpg'
import { MentorshipHelper } from '@/common/crud-helpers/mentorship'
import { SettingsHelper } from '@/common/crud-helpers/settings'
import MentorshipsList from '@/modules/mentorship/components/list/MentorshipsList'
import _ from 'lodash'
import cloneDeep from 'lodash/cloneDeep'
import { mapGetters } from 'vuex'
export default {
    components: {
        MentorshipsList,
    },
    data() {
        return {
            pageBanner: {
                imgBig: imgBig,
                imgLarge: imgLarge,
                imgMedium: imgMedium,
            },
            banner: '',
            pagehead: {
                title: '',
                subtitle: '',
                shortdes: '',
            },
            mentorships: [],
            filters: {
                limit: 6,
                offset: 0,
            },
            loadding: true,
        }
    },
    async created() {
        this.getMentorships()
        if (!this.dashboardSettings || !this.dashboardSettings.dashboard_tabs) {
            await SettingsHelper.getDashboardSettings()
        } else if (
            this.dashboardSettings &&
            this.dashboardSettings.dashboard_tabs
        ) {
            this.setTitleAndDescription()
        }
    },
    methods: {
        getMentorships() {
            this.loadding = true
            MentorshipHelper.list(this.filters)
        },
        getNextData() {
            window.onscroll = () => {
                let bottomOfWindow =
                    document.documentElement.scrollTop + window.innerHeight >
                    document.documentElement.offsetHeight - 600
                if (bottomOfWindow && !this.loadding) {
                    let next = this.getNextOffset(this.mentorshipList.next)
                    if (next) {
                        this.loadding = true
                        this.filters.offset = next
                        this.getMentorships()
                    }
                }
            }
        },
        setTitleAndDescription() {
            let activeTab = this.dashboardSettings.dashboard_tabs.filter(tab =>
                tab.value.url.includes(this.$route.name),
            )[0]
            this.banner = activeTab.value.banner
            this.pagehead.title = activeTab.value[`title_${this.$i18n.locale}`]
            this.pagehead.shortdes =
                activeTab.value[`description_${this.$i18n.locale}`]
        },
    },
    mounted() {
        this.getNextData()
    },
    computed: {
        ...mapGetters(['mentorshipList', 'dashboardSettings']),
    },
    watch: {
        mentorshipList() {
            let allMentorships = _.concat(
                this.mentorships,
                this.mentorshipList.results,
            )
            this.mentorships = cloneDeep(allMentorships)
            this.loadding = false
        },
        dashboardSettings() {
            this.setTitleAndDescription()
        },
    },
}
</script>

<style lang="scss" scoped>
.sidenav-wrapper {
    margin-top: 0;
    .right-col {
        /deep/.page {
            &__title {
                padding-bottom: 28px;
                @media screen and (min-width: 1600px) {
                    padding-bottom: 32px;
                }
                &:after {
                    width: rem(470px);
                }
            }
        }
        @media screen and (max-width: 767px) {
            padding-top: rem(28.8px);
        }
    }
}
/deep/.page-contents {
    padding-top: rem(104px);
}
/deep/.page__title {
    margin: 0 !important;
    &:after {
        width: rem(470px);
    }
    @media screen and (max-width: 991px) {
        padding-left: 0 !important;
        &:after {
            width: rem(300px) !important;
        }
    }
}
.mentorship {
    margin-top: rem(35px);
    @media screen and (max-width: 1600px) {
        margin-top: rem(43.2px);
    }
    @media screen and (max-width: 1450px) {
        margin-top: rem(27.2px);
    }
    @media screen and (max-width: 1199px) {
        margin-top: rem(28.8px);
    }
    @media screen and (max-width: 991px) {
        margin-top: rem(14.4px);
    }
    @media screen and (max-width: 767px) {
        margin-top: 0;
    }
    /deep/ {
        .table {
            padding-left: 15px;
        }
    }
}

/deep/ {
    .content-wrapper {
        .page__head {
            min-height: auto;
        }
    }
    @media screen and (max-width: 1199px) {
        .page-banner {
            --h: 597px;
        }
        .content-wrapper {
            .page__head {
                min-height: 120px;
            }
        }
    }
    @media screen and (max-width: 991px) {
        .page-contents {
            padding-top: rem(65px);
        }
        .table {
            margin-top: rem(60px);
        }
        .page-banner {
            --h: 571px;
        }
        .sidenav-wrapper {
            .right-col {
                .page__title {
                    padding-top: rem(55px);
                }
            }
        }
    }
    @media screen and (max-width: 767px) {
        .page-banner {
            --h: 400px;
        }
        .content-wrapper {
            .page__head {
                min-height: 130px;
            }
        }
        .page-contents {
            padding-top: 0;
        }
        .sidenav-wrapper {
            .right-col {
                .page__title {
                    padding-top: rem(51px);
                }
            }
        }
    }
}

// ar style
.ar {
    .page__title {
        margin: 0 !important;
        @media screen and (max-width: 991px) {
            padding-right: 0 !important;
        }
    }
    .mentorship {
        margin-top: rem(22.4px);
        @media screen and (max-width: 1600px) {
            margin-top: rem(28.8px);
        }
        @media screen and (max-width: 1450px) {
            margin-top: rem(27.2px);
        }
        @media screen and (max-width: 1199px) {
            margin-top: rem(28.8px);
        }
        @media screen and (max-width: 991px) {
            margin-top: 20px;
        }
        @media screen and (max-width: 767px) {
            margin-top: 0;
        }
        /deep/ {
            .table {
                padding-right: 15px;
            }
        }
    }
    .sidenav-wrapper {
        .right-col {
            @media screen and (max-width: 991px) {
                padding-top: 44px;
            }
            @media screen and (max-width: 767px) {
                padding-top: 19px;
            }
        }
    }
}
</style>

<template>
    <div>
        <div class="table">
            <div class="table__head">
                <div class="table__row">
                    <div class="table__row--cell text-center" data-width="12">
                        {{ $t('Idea ID') }}
                    </div>
                    <div class="table__row--cell" data-width="26">
                        {{ $t('Idea Name') }}
                    </div>
                    <div class="table__row--cell text-center" data-width="25">
                        {{ $t('Mentorship Status') }}
                    </div>
                    <div class="table__row--cell text-center" data-width="22">
                        {{ $t('Mentor Name') }}
                    </div>
                    <div class="table__row--cell text-center" data-width="15">
                        {{ $t('Pending task') }}
                    </div>
                </div>
            </div>
            <div class="table__body">
                <div
                    class="table__row"
                    v-bind:key="data.data"
                    v-for="data in mentorships"
                >
                    <div
                        class="table__row--cell text-center"
                        data-width="12"
                        :data-label="$t('Idea ID')"
                    >
                        #{{ data.mentee.object_id }}
                    </div>
                    <div
                        class="table__row--cell"
                        data-width="26"
                        :data-label="$t('Idea Name')"
                    >
                        {{ data.mentee.title }}
                    </div>
                    <div
                        class="table__row--cell text-center"
                        :class="status[data.status].class"
                        data-width="25"
                        :data-label="$t('Mentorship Status')"
                    >
                        {{ status[data.status].name }}
                    </div>
                    <div
                        class="table__row--cell text-center"
                        data-width="22"
                        :data-label="$t('Mentor Name')"
                    >
                        {{ data.mentor_details.name }}
                    </div>
                    <div
                        class="table__row--cell text-center"
                        data-width="15"
                        :data-label="$t('Pending task')"
                    >
                        {{ data.pending_tasks }}
                    </div>
                    <router-link
                        :to="{
                            name: 'mentorship-details',
                            params: {
                                uuid: data.uuid,
                            },
                        }"
                        class="abs-link"
                    >
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        mentorships: Array,
    },
    data() {
        return {
            status: {
                new: {
                    name: this.$t('New'),
                    class: 'new',
                },
                completed: {
                    name: this.$t('Completed'),
                    class: 'compeleted',
                },
                inprogress: {
                    name: this.$t('In-Progress'),
                    class: 'In-progress',
                },
            },
        }
    },
}
</script>

<style lang="scss" scoped>
/deep/ {
    .table {
        padding-left: rem(30px);
        &__head {
            border-color: #fdffd5;
        }
        &__row {
            border-color: #fdffd5;
        }
        @media screen and (max-width: 991px) {
            margin-top: rem(30px);
        }
    }
}

// ar style

.ar {
    .table {
        padding-right: rem(30px);
        padding-left: 0;
    }
}
</style>

import { ApiServiceHelper } from '../crud-helpers'

import { MENTORSHIP } from '@/modules/mentorship/store/actions'

export const MentorshipHelper = {
    list(params) {
        return ApiServiceHelper.execAction(MENTORSHIP.LIST, params)
    },
    get(params) {
        return ApiServiceHelper.execAction(MENTORSHIP.GET, params)
    },
    updateMyMentorship(params) {
        return ApiServiceHelper.execAction(
            MENTORSHIP.UPDATE_MY_MENTORSHIP,
            params,
        )
    },
    getProfile(params) {
        return ApiServiceHelper.execAction(MENTORSHIP.GET_PROFILE, params)
    },
    createTask(params) {
        return ApiServiceHelper.execAction(MENTORSHIP.CREATE_TASK, params)
    },
    listTask(params) {
        return ApiServiceHelper.execAction(MENTORSHIP.LIST_TASK, params)
    },
    listMyTask(params) {
        return ApiServiceHelper.execAction(MENTORSHIP.LIST_MY_TASK, params)
    },
    updateTask(params) {
        return ApiServiceHelper.execAction(MENTORSHIP.UPDATE_TASK, params)
    },
    listNote(params) {
        return ApiServiceHelper.execAction(MENTORSHIP.LIST_NOTE, params)
    },
    addNote(uuid) {
        return ApiServiceHelper.execAction(MENTORSHIP.ADD_NOTE, uuid)
    },
}
